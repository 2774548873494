.dawn,
.morning,
.afternoon,
.dusk,
.night {
  width: 100%;
  height: 100%;
}

.dawn {
  background: linear-gradient(
    to bottom right,
    #f4e6da,
    #d7bee0,
    #abadf2,
    #7384ee
  );
}
.morning {
  background: linear-gradient(
    to bottom right,
    #36bcff,
    #a3d6f7,
    #a7e2ff,
    #b0eed1
  );
}
.afternoon {
  background: linear-gradient(to bottom right, #015aff, #52a0fe, #a7e2ff);
}
.dusk {
  background: linear-gradient(
    to bottom right,
    #a3ace2,
    #d5bdb5,
    #dba3a3,
    #ff965e
  );
}
.night {
  background: linear-gradient(
    to bottom right,
    #000d27,
    #081d43,
    #003a6c,
    #3b6990
  );
}
.disagree {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 3rem;
}

.weatherContainer {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 250px auto;
  color: white;
  position: absolute;

  .weatherContent {
    display: grid;
    height: 70%;
    place-items: center;
    grid-template-rows: auto 1fr 1fr;
    padding-top: 60px;
    padding-left: 60px;

    .currentWeather {
      font-size: 18px;
    }

    .temperature {
      font-size: 38px;
      font-weight: bold;
    }
  }
  .location {
    display: flex;
    align-items: center;
    position: absolute;
    color: white;
    top: 15px;
    left: 15px;

    .locationIcon {
      width: 10px;
      margin-right: 5px;
      color: white;
    }

    .locationName {
      margin-top: 3px;
      font-size: 13px;
    }
  }
}
.rightContainer {
  display: flex;
  width: 100%;
  height: 100%;
}
