.footer {
  position: relative;
  width: 100%;
  height: 80px;
  border-top: 1px solid rgba(128, 128, 128, 0.203);
}

.moveTopButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  z-index: 2;
  background-color: rgba(128, 128, 128, 0.155);

  .moveTopButton {
    width: 18px;
    height: 18px;
    background-image: url('../../..//public/image/footer-upArrow.png');
    background-size: cover;
  }
}
