.blogContainner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;

  .blogWrapper {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 100%;
    padding: 10px;
  }
  .blogcontent {
    width: 120vh;
    background-color: white;
    .blogHeader {
      margin-top: 5%;
      margin-left: 2%;
      width: 100%;
      .blogName {
        margin-bottom: 5%;
        font-size: 40px;
      }
      .titleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .allContents {
          font-size: 30px;
          margin-right: 5px;
        }
        .border {
          width: 85%;
          height: 0;
        }
      }
    }
  }
}
