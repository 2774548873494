.loginInput {
  border-bottom: solid 1px #d3d3d3;
  width: 80%;
  padding: 20px 0px 4px 2px;
  color: #6f6f6f;
  &:focus + .labelName .contentName,
  &:valid + .labelName .contentName {
    transform: translateY((-150%));
    font-size: 13px;
    color: #6d6d6d;
  }
}

.formWrap {
  position: relative;

  .labelName {
    display: block;
    position: absolute;
    bottom: 4px;
    left: 2px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    font-size: 15px;
    color: #b3b3b3;
    &::after {
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0%;
    }
  }
  .contentName {
    position: absolute;
    bottom: 7px;
    left: 0px;
    transition: all 0.3s ease;
  }
}
