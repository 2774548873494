@media (max-width: 3000px) {
  .postBox {
    width: 22rem !important;
  }
}

@media (max-width: 2300px) {
  .postBox {
    width: 18rem !important;
  }
}

@media (max-width: 1800px) {
  .postBox {
    width: 16rem !important;
  }
}

@media (max-width: 1645px) {
  .postBox {
    width: 19rem !important;
  }
}

@media (max-width: 1500px) {
  .postBox {
    width: 17rem !important;
  }
}

@media (max-width: 1350px) {
  .postBox {
    width: 15rem !important;
  }
}

@media (max-width: 1290px) {
  .postBox {
    width: 20rem !important;
  }
}

@media (max-width: 1100px) {
  .postBox {
    width: 16rem !important;
  }
}

@media (max-width: 920px) {
  .postBox {
    width: 23rem !important;
  }
}

@media (max-width: 600px) {
  .postBox {
    width: 17rem !important;
  }
}

.allPostContainer {
  display: grid;
  grid-template-rows: 50px auto;
  gap: 50px;
  width: 100%;
  margin-bottom: 50px;

  .allPostTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 23px;

    .moreButton {
      width: 45px;
      height: 22px;
      margin-left: 15px;
      border-radius: 30px;
      color: white;
      background-color: #b6c4f5;
      font-size: 11px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: #8da3f0;
      }
    }
  }

  .allPostWrap {
    display: grid;
    place-items: center;
    grid-template-columns: 50px 1fr 50px;

    .chevron {
      color: #b6c4f5;
      font-size: 30px;
      cursor: pointer;

      &:hover {
        color: #8da3f0;
      }
    }
  }
}

.allPostContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, max-content));
  justify-content: center;
  place-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;

  .postBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 20rem;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 3px #eef0f2;

    &:hover {
      transform: scale(1.03);
      transition: transform 200ms ease-in-out;
    }

    .postUser {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e6e8ea;

      .userInfo {
        display: flex;
        align-items: center;
        padding: 10px 0 10px 15px;

        .userImage {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          object-fit: cover;
        }

        .userName {
          padding-left: 10px;
          font-size: 14px;
        }
      }
      .postingDate {
        padding-right: 15px;
        color: #bcbdc0;
        font-size: 11.5px;
      }
    }

    .postContent {
      height: 100%;
    }

    .postImg {
      display: flex;
      justify-content: center;
      height: 170px;
      .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .postTitle {
      display: block;
      align-items: center;
      padding: 15px;

      .titleText {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.5rem;
        margin-bottom: 5px;
        font-size: 16px;
        overflow: hidden;
      }
      .postingContent {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: 100%;
        line-height: 1.5rem;
        margin-bottom: 5px;
        color: #6c6c6c;
        font-size: 13px;
        overflow: hidden;
      }
      .postingLongContent {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        width: 100%;
        line-height: 1.5rem;
        margin-bottom: 5px;
        color: #6c6c6c;
        font-size: 13px;
        overflow: hidden;
      }
    }
  }
}
