.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.128);
  background-color: white;
  .logo {
    height: 50px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .headerRightArea {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;

    .searchbar {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 10px;
      .inputFocus {
        width: 150px;
        height: 30px;
        border-radius: 5px;
        background-color: rgb(242, 242, 242);

        animation: inputFocus 0.5s ease forwards;
        @keyframes inputFocus {
          0% {
            transform: scaleX(0.5);
            transform-origin: 100% 100%;
            opacity: 0;
          }
          100% {
            transform: scaleX(1), opacity(1);
            transform-origin: 100% 100%;
            opacity: 1;
          }
        }
      }

      .inputBlur {
        width: 150px;
        height: 30px;
        border-radius: 30px;
        background-color: rgb(242, 242, 242);

        animation: inputBlur 0.5s ease forwards;
        @keyframes inputBlur {
          0% {
            transform: scaleX(1);
            transform-origin: 100% 100%;
            opacity: 1;
          }
          100% {
            transform: scaleX(0.5);
            transform-origin: 100% 100%;
            opacity: 0.7;
          }
        }
      }

      .searchIconDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2px;
        right: 0;
        height: 25px;
        // background-color: rgb(242, 242, 242);
        border-radius: 50px;

        .searchIcon {
          background-image: url('../../..//public/image/header-search.png');
          background-size: cover;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .writePostBtnDiv {
      margin-right: 10px;

      .writePostBtn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 30px;
        font-size: 14px;
        border-radius: 30px;
        background-color: rgb(242, 242, 242);
      }
    }

    .userNameDiv {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      .userMenuDiv {
        z-index: 30;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 45px;
        right: 5px;
        width: 200px;
        border: 1px solid rgba(128, 128, 128, 0.242);
        border-radius: 10px;
        background-color: white;
        z-index: 2;

        .userMenu {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          border-bottom: 1px solid rgba(128, 128, 128, 0.242);
          font-size: 14px;
        }
      }

      .userName {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        margin-right: 15px;
        font-size: 14px;
        background-color: rgb(242, 242, 242);

        .userNameText {
          margin: 0 10px;
        }
      }

      .loginBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 30px;
        margin-right: 15px;
        font-size: 14px;
        background-color: rgba(128, 128, 128, 0.156);
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
