.setting {
  min-height: 100vh;
  background-color: #e8eff3;
  overflow-y: hidden;
}
.settingContainer {
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: calc(100vh - 100px);
  padding-top: 50px;
  background-color: white;

  .infoWrapper {
    display: flex;
    align-items: center;
    margin-left: 15%;
    .imgWrapper {
      position: relative;
      .profileImg {
        width: 300px;
        height: 300px;
        border-radius: 70%;
      }
      .editButton {
        position: absolute;
        bottom: 0;
        right: 10%;
        width: 50px;
        height: 30px;
        border-radius: 20%;
        background-color: black;
        color: white;
        text-align: center;
        cursor: pointer;
      }
    }
    .blogInfo {
      width: 100%;
      margin-left: 5%;
      .infoWrap {
        margin: 20px;
        font-size: 20px;
        .infoTitle {
        }
        .infoContent {
          margin-top: 10px;
          width: 100%;
          font-weight: 300;
        }
      }
    }
  }
  .saveWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 150px auto;
    padding: 20px;
    width: 600px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #cdcdcd;
    background-color: #ffebeb;
    .saveComment {
      padding-top: 5px;
    }
    .saveButton {
      height: 40px;
      border-radius: 10px;
      padding-left: 5px;
      padding-right: 5px;
      border: 1px solid #d9d9d3;
      background-color: white;
      text-align: center;
      cursor: pointer;
    }
  }
}
