.container {
  background-color: #e8eff3;
  height: 100%;
  .contentWrap {
    width: 67%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
    padding-bottom: 6%;
    .category {
      display: inline-block;
      width: 30%;
      padding: 1%;
      text-align: center;
      margin-bottom: 0.4%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #fdfdfd;
      color: #9c9c9c;
      font-size: 15px;
    }
    .innerWrap {
      padding: 3% 3% 2% 3%;
      background-color: #ffffff;
      height: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .btnWrap {
        font-size: 14px;
        text-align: right;
        color: #9b9b9b;
        .shareBtn,
        .deleteBtn {
          cursor: pointer;
        }
        .editBtn {
          cursor: pointer;
          margin-right: 1%;
        }
      }
      .headerWrap {
        height: 18%;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        flex-direction: row;
        .profile {
          width: 200px;
          padding-bottom: 1%;
          cursor: pointer;
          .profileImg {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            cursor: pointer;
          }
          .name {
            margin-top: 10px;
            color: #909090;
          }
          text-align: center;
        }
        .titleWrap {
          width: 85%;
          .title {
            font-size: 40px;
            font-weight: 300;
            margin-top: 6%;
            margin-bottom: 12px;
          }
          .time {
            font-size: 13px;
            color: #909090;
            font-weight: 200;
            padding-bottom: 1%;
          }
        }
      }
      .tags {
        border-top: 1px solid #e5e5e5;
        padding-top: 2%;
        color: #999999;
        font-size: 15px;
      }
      .tag {
        display: inline-block;
        margin-right: 2%;
      }
      .content {
        text-align: center;
        padding: 0% 5% 5% 5%;

        .thumbnail {
          max-width: 70%;
          max-height: 60%;
          margin-top: 5%;
        }
        .postContent {
          text-align: left !important;
          margin-top: 5%;
          line-height: 30px;
        }
      }
    }

    .comments {
      background-color: #ffffff;
      margin-top: 2%;
      min-height: 300px;
      border-radius: 10px;

      .commentInput {
        display: flex;
        height: 50px;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;
        ::placeholder {
          color: #9a9a9a;
          font-size: 16px;
          font-weight: 300;
        }
        .InputText {
          width: 91%;
        }
        .commentImg {
          margin-left: 1%;
          margin-right: 1%;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .commentBtn {
          color: #8da3f0;
          font-size: 16px;
          cursor: pointer;
          &:disabled {
            cursor: default;
            color: #d8e0ff;
          }
        }
      }
      .commentList {
        color: #585858;
        margin-top: 5%;
        text-align: center;
      }
      .commentListWrap {
        padding: 5% 8%;
      }
    }
  }
}
