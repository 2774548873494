@media (max-width: 1100px) {
  .newPostContent {
    grid-template-columns: 200px 1fr !important;
    height: 150px !important;
  }
  .thumbnailContent {
    display: none !important;
  }
}

.newPostContainer {
  display: grid;
  grid-template-rows: 70px auto;
  width: 100%;

  .newPostTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 23px;

    .moreButton {
      width: 45px;
      height: 22px;
      margin-left: 15px;
      border-radius: 30px;
      color: white;
      background-color: #b6c4f5;
      text-align: center;
      font-size: 11px;
      cursor: pointer;

      &:hover {
        background-color: #8da3f0;
      }
    }
  }

  .newPostContent {
    display: grid;
    grid-template-columns: 200px 1fr 250px;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 1.5px solid #e8eff3;
    cursor: pointer;
    &:hover {
      background-color: #e8eff3;
    }

    .userInfo {
      display: grid;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 70%;
      gap: 10px;
      font-size: 13px;

      .userImg {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .postContent {
      display: grid;
      grid-template-rows: 20px 30px auto;
      align-items: center;
      gap: 5px;
      height: 70%;

      .postingTime {
        font-size: 11px;
        color: #6c6c6c;
      }
      .postingTitle {
        font-size: 1rem;
        font-weight: 600;
      }
      .postingContent {
        display: flex;
        align-items: start;
        height: 100%;

        .text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          width: 100%;
          color: #6c6c6c;
          font-size: 14px;
          line-height: 1.5rem;
          overflow: hidden;
        }
      }
    }
  }
}
.thumbnailContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;

  .thumbnailImg {
    display: flex;
    width: 80%;
    .postThumbnail {
      height: 110px;
      width: 100%;
      object-fit: cover;
    }
  }
}
.makeBuddyContainer {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
