.profileContainer {
  margin: 8% 0 0 5%;
  width: 90%;
  height: 100%;
  border-radius: 10px;
  background-color: white;

  border-bottom: none;
  .headerWrapper {
    display: flex;
    padding: 20px;
    .profileImg {
      width: 100px;
      height: 100px;
      border-radius: 70%;
      font-size: 22px;
    }
    .nicknameWrapper {
      h2 {
        margin: 20px;
        font-size: 25px;
        margin-left: 25px;
      }
      .follow {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-left: 20px;
        cursor: pointer;

        .following,
        .follower {
          display: flex;
          margin-left: 5px;

          .count {
            margin-left: 5px;
            color: #8da3f0;
          }
        }
      }
    }
  }
  .content {
    padding: 10px 30px;
  }
  .grass {
    width: 100%;
    height: 200px;
    .writeBtn {
      margin: 20px 20%;
      width: 200px;
      height: 40px;
      border-radius: 10px;
      background-color: white;
      text-align: center;
      z-index: 100;
      cursor: pointer;
    }
  }
}
