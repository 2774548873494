.react-calendar {
  width: 320px;
  max-width: 100%;
  background: #ffffff;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  border-top: 3px solid #e8eff3;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 40px;
  text-align: center;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
}
.react-calendar__month-view__days__day--neighboringMonth {
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
}
.react-calendar__tile--now {
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
}
.react-calendar__tile--hasActive {
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
}
.react-calendar__tile--active {
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
}
.react-calendar--selectRange .react-calendar__tile--hover {
}
.dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: flex;
  margin-left: 1px;
}
