.modalBackground {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #0000004d;
  text-align: center;
  z-index: 9;

  .followContainer {
    display: grid;
    position: relative;
    grid-template-rows: 50px auto;
    width: 330px;
    height: 400px;
    top: 0%;
    left: 0%;
    background-color: #ffffff;
    border-radius: 10px;

    .followContent {
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: scroll;
    }

    .backBtn {
      display: flex;
      position: absolute;
      width: 45px;
      height: 50px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
    }

    .followBtn {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      border-radius: 10px 10px 0px 0px;

      .userFollowing {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 1.5px solid #8da3f0;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }

      .userFollower {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 1.5px solid #8da3f0;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }

      .notSelected {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        color: gray;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
