.columnInfoWrapper {
  margin-left: auto;
  margin-right: auto;
  // background-color: #fafafa;
  padding: 19px;
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  width: 600px;
}

.linkWrapper {
  padding: 10px;
  margin: 32px;
  display: flex;
  flex-direction: column;

  > .button {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: rgb(68, 68, 68);
    margin: 20px 0px 0px;

    .PlusImg {
      width: 20px;
      aspect-ratio: auto 20 / 20;
      height: 20px;
      margin-right: 4px;
    }
  }

  .linkTitle {
    border-bottom: 1px solid rgb(218, 218, 218);
    padding-bottom: 3px;
    font-size: 20px;
  }
  .linkItemWrapper {
    display: flex;
    position: relative;
    height: 54px;
    padding: 20px 0 0;
    align-items: center;

    .button {
      position: absolute;
      top: 36px;
      right: 15px;

      .trashIcon {
        width: 28px;
        aspect-ratio: auto 28 / 28;
        height: 28px;
      }
    }



    .linkImg {
      width: 40px;
      aspect-ratio: auto 40 / 40;
      height: 40px;
    }

    .wrapper {
      margin-left: 16px;
      width: calc(100% - 180px);
    }
    .label {
      display: block;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.5px;
      width: 100%;
      &:hover {
        background-color: #f0f0f0;
      }
      &:focus {
        background-color: #f0f0f0;
      }
    }

    .input {
      width: 100%;
      padding: 3px;
      &:hover {
        background-color: #f0f0f0;
      }
      &:focus {
        background-color: #f0f0f0;
      }
    }

  }

}

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .imgWrapper {
    position: relative;
    width: 180px;
    height: 180px;
    padding: 5px;
    margin: 2px;
    .profileImg {
      border-radius: 70%;
      width:100%;
      height:100%;
      border: 1px solid #d7d7d7;
    }
    .editButton {
      position: absolute;
      bottom: 0;
      right: 10%;
      width: 50px;
      height: 30px;
      border-radius: 20%;
      background-color: black;
      color: white;
      text-align: center;
      cursor: pointer;
      bottom: 4px;
      right: 10px;
    }
    .input {
      display: none;
    }
  }
  .blogInfo {
    // margin-left: 5%;
    display: flex;
    flex-direction: column;

    .infoWrap {
      margin: 10px;
      font-size: 20px;
      .infoTitle {
      }
      .infoContent {
        margin-top: 10px;
        width: 230px;
        font-weight: 300;

        border-radius: 8px;
        border: 1px solid #979794;
        padding: 3px;
        padding-left: 6px;
        background-color: #ffffff;
      }
    }
  }

  .divider {
    height: 167px;
    width: 1px;
    background-color: lightgray;
  }
}
