@keyframes fall {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
.rainContainer {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  position: absolute;
}
.rainDrop {
  font-weight: lighter;
  animation: fall 2s linear infinite;
}
