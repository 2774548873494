.topicMenu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  width: 100px;
  height: 27px;
  padding: 3px 0 3px 0;
  border-radius: 10px;
  border: 1.5px solid #b6c4f5;
  color: #414141;
  font-size: 14px;
  cursor: pointer;

  .angle {
    color: #8da3f0;
    font-size: 15px;
  }
}

.topicWrap {
  position: absolute;
  height: auto;
  border-radius: 10px;
  border: 1px solid #e6e8ea;
  margin-top: 10px;

  .topicList {
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100%;
    overflow-y: scroll;
    border-radius: 10px;

    .topicItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      padding: 3px 0 3px 0;
      border-bottom: 1px solid #e6e6e6;
      background-color: white;
      list-style: none;
      font-size: 14px;
      z-index: 1;
      cursor: pointer;
    }
    :hover {
      background-color: #b6c4f5;
      color: white;
    }
    :last-child {
      border-bottom: 0;
    }
  }
}
