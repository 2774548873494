.blogContainner {
  width: 100%;
  cursor: pointer;
  .contents {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid #e6e8ea;

    &:hover {
      background-color: #f8f8f8;
    }
    .contentWrapper {
      flex-direction: column;
      .contentTitle {
        margin: 15px 0;
        font-size: 25px;
      }
      .titleWrapper {
        display: flex;
        .reply {
          margin: 15px 0;
          padding-top: 8px;
          margin-left: 5px;
          color: red;
        }
      }
    }
    .contentWrap {
      max-height: 100px;
      .content {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        width: 100%;
        font-size: 14px;
        line-height: 1.5rem;
        overflow: hidden;
      }
    }
    .contentImg {
      width: 200px;
      height: 100px;
    }
  }
}
