@media (max-width: 3000px) {
  .homeContainer {
    width: 70vw;
  }
}

@media (max-width: 2000px) {
  .homeContainer {
    width: 80vw;
  }
}

@media (max-width: 750px) {
  .homeContainer {
    width: 100vw;
    padding: 0 !important;
    .postContent {
      padding: 0;
    }
  }
}

.home {
  display: grid;
  justify-content: center;
  background-color: #e8eff3;

  .carouselContent {
    display: flex;
    width: 100%;
    height: 300px;
  }

  .homeContainer {
    display: block;
    background-color: white;

    .postContent {
      padding: 0 50px 0 50px;

      .allPost {
        display: flex;
        width: 100%;
        margin-top: 25px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 2px solid #e8eff3;
      }
      .newPost {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
        padding-top: 30px;
      }
    }
  }
}
