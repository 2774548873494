.FinalWrap {
  .totalWrap {
    width: 100%;
    height: 100%;
    min-width: 1200px;
    background-color: #e8eff3;
    display: flex;
    flex-direction: column;
    .container {
      height: 100%;
      background-color: #e8eff3;
      width: 50%;
      margin-left: auto;

      margin-right: auto;
      .nav {
        background-color: #595959;
        margin-top: 7%;
        margin-bottom: 0.2%;
      }
      .containerWrap {
        background-color: #ffffff;
        padding-bottom: 2%;
      }
      .after {
        position: relative;
        height: 260px;
      }
      .before {
        position: relative;
        height: 80px;
      }
      .InputWrap {
        position: relative;
        margin-left: 1.5%;
        margin-right: 1.5%;
        margin-bottom: 1%;

        input[type='file'] {
          width: 0;
          height: 0;
          padding: 0;
          overflow: hidden;
          border: 0;
          background-color: #595959;
        }

        .thumbImg {
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          height: 260px;
          position: absolute;
          border-radius: 10px;
        }
        .grayBack {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 260px;
          z-index: 3;
          background-color: rgb(89, 89, 89, 0.75);
          border-radius: 10px;
        }
        .titleInput {
          position: relative;
          height: 120px;
          z-index: 10;
          box-sizing: border-box;
          margin-left: 1%;
          width: 95%;
          font-size: 38px;
          font-weight: 300;
          padding: 20px 10px 30px 0px;
          color: #595959;
          &::placeholder {
            color: #c3c3c3;
          }
          &.after {
            color: white;
            margin-top: 150px;
          }
        }

        .fileInput {
          width: 30px;
          height: 30px;
          text-align: right;
          position: absolute;
          right: 0px;
          right: 10px;
          cursor: pointer;
          z-index: 8;
          top: 20px;
        }
        .imgDeleteBtn {
          cursor: pointer;
          position: absolute;
          top: 25px;
          right: 50px;
          z-index: 8;
          width: 20px;
          height: 20px;
        }
      }
      .tag {
        display: flex;
        width: 25%;
        display: inline-block;
        margin-left: 76%;
        margin-top: 10%;
        position: fixed;
      }
    }
  }
}
